<template>
  <div class="wrapper">
    <router-view name="message_container" ref="message-container" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customCog } from "@/assets/icons";
library.add(customCog);

export default {
  name: "Container",
  computed: {
    saveBtnDisabled() {
      return false;
      // return this.$store.getters['message/countContentChanges'].saveBtnDisabled;
    },
    ...mapGetters("helper", ["labels"]),
    localData() {
      return this.createMessageData();
    },
    pageHeaderData() {
      let output = {
        preTitle: this.labels.message,
        title: this.localData.title,
        btnList: [
          {
            type: "label",
            link: {
              name: "message-recipients",
              params: { kind: "message-recipients" },
            },
            label: this.labels.recipients,
            class: `btn-type-label ${
              this.$route.name === "message-recipients" ? "current" : ""
            }`,
          },
          {
            type: "label",
            link: {
              name: "message-settings",
              params: { kind: "message-settings" },
            },
            label: this.labels.message_content,
            class: `btn-type-label ${
              this.$route.name === "message-settings" ? "current" : ""
            }`,
          },
          {
            type: "button",
            label: this.labels.save,
            variant: "btn-green",
            class: "btn-type-button",
            action: this.saveAction,
            disabled: this.saveBtnDisabled,
          },
          {
            type: "button",
            label: this.labels.delete,
            variant: "btn-danger",
            class: "btn-type-button",
            action: this.deleteAction,
          },
        ],
      };
      return output;
    },
  },
  methods: {
    ...mapMutations("helper", {
      setPageHeaderData: "setPageHeaderData",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("message", {
      getSingle: "getSingle",
      deleteMessage: "delete",
    }),
    ...mapGetters("message", {
      createMessageData: "createMessageData",
    }),
    getSingleData() {
      this.getSingle({ id: this.$route.params.id })
        .then((res) => {
          if (res.success) {
            this.loopKey = Date.now();
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    saveAction() {
      let actionName = "";

      if (this.$route.name === "message-recipients") actionName = "saveData";

      if (this.$route.name === "message-settings")
        actionName = "saveGeneralSettings";

      this.$refs["message-container"][actionName]();
    },
    deleteAction() {
      console.log(this.$route.params.id);
      this.deleteMessage({ id: this.$route.params.id }).then((res) => {
        if (res) this.$router.push({ name: "messages" });
      });
    },
  },
  beforeMount() {
    this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
  },
  mounted() {
    this.getSingleData();
    this.setPageHeaderData(this.pageHeaderData);
  },
  beforeDestroy() {
    this.setPageHeaderData({});
  },
  watch: {
    pageHeaderData(value) {
      this.setPageHeaderData(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
